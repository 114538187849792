import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';

import { Fee, Flight } from '@/API/models';
import { Input } from '@/components/Forms';
import { CustomDateInput } from '@/components/Forms/DateInput';
import EditText, { EditAirport, EditTime } from '@/components/Forms/EditText';
import { useChangeFlightForm } from '@/Utils/formHooks/useChangeFlightForm';
import { formatDate, formatMoney } from '@/Utils/helpers';

export type ChangeFlightProps = Flight & {
  action: string;
  reason: string;
  title: string;
  comments: string;
};

const ChangeFlight: React.FC<ChangeFlightProps> = (props) => {
  const { realOnSubmit, ...changeFlightForm } = useChangeFlightForm(props);

  return (
    <Formik {...changeFlightForm}>
      {({ values, touched }) => {
        const appliedFees = values.fees_attributes.reduce((prev, current) => {
          if (current._destroy) {
            return prev + 0;
          }
          return prev + current.amount;
        }, 0);
        const total = values.aircraft_emptyleg_price + appliedFees;

        return (
          <Form>
            {/* <DebugForm /> */}
            <div className='trip'>
              <div className='title'>
                {props.title ?? 'Modified Flight Request'}
              </div>
              <div className='multidate'>
                <dl className='flights'>
                  {/* <dt>Sun, Aug 29</dt> */}
                  <dt>
                    <CustomDateInput
                      name='departure_date'
                      Btn={() => (
                        <>
                          {formatDate(values.departure_date)}
                          <FiEdit className='mx-4' />
                        </>
                      )}
                    />{' '}
                    <EditTime
                      name='departure_time'
                      noLabel
                      text={values.timezone}
                      style={{ margin: 0 }}
                    />
                  </dt>
                  <dd>
                    <ul className='times'>
                      <li>
                        <mark>
                          <EditAirport name='departure_airport_code' />
                        </mark>
                        <p>{values.departure_airport_city}</p>
                      </li>

                      <li className='to'>
                        <i className='fa fa-angle-right'></i>
                        <EditText
                          name='flight_time'
                          component={() => (
                            <p className='mx-6 text-gray-600'>
                              {`${values.flight_time}h`}{' '}
                              <FiEdit className='ml-4' />
                            </p>
                          )}
                        />
                      </li>

                      <li>
                        <mark>
                          <EditAirport name='arrival_airport_code' />
                        </mark>
                        <p>{values.arrival_airport_city}</p>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
              <dl className='subtotal'>
                <dt>All in one-way charter special</dt>
                {/* <dd>$1,700.01</dd> */}
                <dt>
                  <EditText
                    name='aircraft_emptyleg_price'
                    text={formatMoney(
                      values.aircraft_emptyleg_price,
                      props.currency,
                    )}
                    type='number'
                  />
                </dt>
              </dl>
              <dl className='subtotal'>
                <dt>
                  Cost breakdown of additional fees for modifying the One-Way
                  Special
                </dt>
              </dl>
              <FieldArray name='fees_attributes'>
                {({ push, remove, replace }) => (
                  <>
                    {values.fees_attributes.map((fee, i) => {
                      if (fee._destroy) {
                        return null;
                      }
                      return (
                        <dl
                          className='subtotal md:flex gap-4 flex-col md:flex-row'
                          key={`fees_attributes.${i}`}
                        >
                          <Input
                            name={`fees_attributes.${i}.name`}
                            placeholder='item'
                            noLabel
                            style={{ margin: 0 }}
                          />
                          <div className='flex gap-4 w-full'>
                            <Input
                              name={`fees_attributes.${i}.amount`}
                              preText={props.currency + ' '}
                              placeholder='000'
                              noLabel
                              style={{ margin: 0 }}
                              type='number'
                              className='w-full'
                            />
                            <button
                              className='btn btn-red-dimmed'
                              type='button'
                              onClick={() => {
                                // fee already in db
                                if (fee.id) {
                                  // add _destroy attribute
                                  return replace(i, {
                                    ...fee,
                                    _destroy: true,
                                  } as Fee);
                                }

                                // fee not yet saved to the db
                                remove(i);
                              }}
                            >
                              <FiTrash />
                            </button>
                          </div>
                        </dl>
                      );
                    })}
                    <dl className='subtotal'>
                      <button
                        className='btn btn-dimmed'
                        type='button'
                        onClick={() => {
                          push({
                            name: '',
                            amount: 0,
                            currency: props.currency,
                          } as Fee);
                        }}
                      >
                        + Additional Fee
                      </button>
                    </dl>
                  </>
                )}
              </FieldArray>
              <div className='p-4 bg-gray-300 '>
                Please provide additional comments to explain the reason for the
                changes.
              </div>
              <dl className='total'>
                <dt>
                  <EditText
                    name='nbr_passengers'
                    text={`${values.nbr_passengers} person(s)`}
                  />
                </dt>
                <dd>{formatMoney(total, props.currency)}</dd>
              </dl>
              {props.comments && (
                <>
                  <dl className='subtotal text-black font-bold'>
                    <dt>User&apos;s Comment</dt>
                  </dl>
                  <dl className='subtotal'>
                    <p>{props.comments}</p>
                  </dl>
                </>
              )}
            </div>

            <Input name='reason' />
            <button
              className='btn mt-5'
              type='button'
              onClick={() => realOnSubmit(values, touched)}
            >
              Confirm Changes
            </button>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ChangeFlight;
