import React from 'react';

export const AircraftPlaceholderIcon = ({
  src,
  width = 60,
  height = 60,
  ...rest
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <img src={src} width={width} height={height} alt='aircraft' {...rest} />
  );
};
